import Swiper, { Pagination, Autoplay } from 'swiper';
import Dropdown from '../../components/other/NDropdown/Dropdown';
import Tabs from '../../components/other/NTabs/Tabs';
import 'swiper/css';
import 'swiper/css/pagination';
import './main.scss';

(function () {
    const $slider1 = document.querySelector('.swiper');

    var swiperInstance = new Swiper($slider1, {
        modules: [Pagination, Autoplay],
        speed: 1000,
        loop: true,
        spaceBetween: 30,
        autoplay: {
            delay: 4000,
            waitForTransition: true,
            disableOnInteraction: false,
        },
        flipEffect: {
            rotate: 30,
            slideShadows: false,
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            renderBullet: function (index, className) {
                return (
                    '<span class="' +
                    className +
                    '">' +
                    '<svg class="fp-arc-loader" width="19" height="19" viewBox="0 0 16 16">' +
                    '<circle class="path" cx="8" cy="8" r="5.5" fill="none" transform="rotate(-90 8 8)" stroke="#26C93C"' +
                    'stroke-opacity="1" stroke-width="1.5px"></circle>' +
                    '<circle class="inner-circle" cx="8" cy="8" r="3" fill="none" stroke="#26C93C" stroke-opacity="1" stroke-width="1px"></circle>' +
                    '</svg></span>'
                );
            },
        },
        on: {
            slideChange: function () {
                updateAltAttributes();
            },
            transitionEnd: function () {
                updateAltAttributes();
            }
        },
    });

    function updateAltAttributes() {
        const slides = $slider1.querySelectorAll('.swiper-slide img');
        slides.forEach((img, index) => {
            img.setAttribute('alt', `Сбербанк Страхование — страховые продукты и услуги фото №${index + 1}`);
        });
    };

    function simulateClick() {
        swiperInstance.autoplay.start();
    }

    setTimeout(simulateClick, 200);

    document.addEventListener('DOMContentLoaded', function () {
        const $dropdownList = document.querySelectorAll('.js-dropdown');
        const $tabs = document.querySelectorAll('.js-tabs');
        const $showMore = document.querySelector('.js-show-more');
        const $productAppendBlock = document.querySelector('.js-product-section-append');
        let numPage = 1;

        $showMore.addEventListener('click', function (e) {
            e.preventDefault();
            var url = this.getAttribute('data-action') + numPage;
            numPage++;

            fetch(url)
                .then(function (response) {
                    return response.text();
                })
                .then(function (data) {
                    if (data) {
                        $productAppendBlock.insertAdjacentHTML('beforeend', data);
                        let newBlocks = $productAppendBlock.querySelectorAll('.news-blocks__item');
                        for (let i = 0; i < newBlocks.length; i++) {
                            newBlocks[i].classList.add('fade-in'); // добавить класс анимации
                        }
                    }
                })
                .catch(function (error) {
                    // Обработка ошибок
                });
        });

        $dropdownList.forEach((dropdown) => {
            new Dropdown(dropdown);
        });

        $tabs.forEach((tab) => {
            new Tabs(tab);
        });

        const button = document.querySelector('.b-footer__agreement_link2');
        button.addEventListener('click', function () {
            button.classList.toggle('active');
        });

        console.log(111, 'main init');
    });
})();
